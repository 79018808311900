import { graphql } from "gatsby";
import React from "react";
import { ContactUs } from "../ContactUs";
import "./styles.css";

const HoneyAdvantages = ({ advantages, formHeader, formBody }) => {
  return <ContactUs className="HoneyAdvantages" advantages={advantages} formHeader={formHeader} formBody={formBody} />;
};

HoneyAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  honeyAdvantages: {
    ...data.honeyAdvantages,
  },
});

const honeyAdvantagesQuery = graphql`
  fragment HoneyAdvantagesFragment on MarkdownRemarkFrontmatter {
    honeyAdvantages {
      advantages {
        title
        text
      }
      formHeader {
        title
        text
      }
    }
  }
`;

export { HoneyAdvantages, honeyAdvantagesQuery };
