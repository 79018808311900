import React from "react";
import cn from "classnames";
import { Image } from "../../Image";

import "./styles.css";

const HoneyHeroImage = ({ className, background, alt }) => {
  return (
    <div className={cn(className, "HoneyHeroImage")}>
      <div className="HoneyHeroImage__image">
        <Image image={background} alt={alt} />
      </div>
    </div>
  );
};

export { HoneyHeroImage };
