import { graphql } from "gatsby";
import React from "react";

import { HoneyHeroContent } from "./HoneyHeroContent";
import { HoneyHeroProduct } from "./HoneyHeroProduct";
import { HoneyHeroImage } from "./HoneyHeroImage";

import "./styles.css";

const HoneyHero = ({ background, alt, subtitle, title, description, products }) => {
  return (
    <div className="HoneyHero">
      <div className="[ HoneyHero__container ] [ Content ]">
        <HoneyHeroContent subtitle={subtitle} title={title} description={description} />

        <div className="HoneyHero__products">
          {products.map((product, index) => (
            <HoneyHeroProduct key={index} {...product} />
          ))}
        </div>
      </div>

      <HoneyHeroImage className="HoneyHero__image" background={background} alt={alt} />
    </div>
  );
};

HoneyHero.getLivePreviewData = ({ data, getAsset }) => ({
  honeyHero: {
    ...data.honeyHero,
    background: getAsset(data.honeyHero.background).url,
    products: data.honeyHero.products.map(product => ({
      ...product,
      photo: getAsset(product.photo).url,
    })),
  },
});

const honeyHeroQuery = graphql`
  fragment HoneyHeroFragment on MarkdownRemarkFrontmatter {
    honeyHero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      description
      products {
        photo {
          childImageSharp {
            fluid(maxWidth: 576) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        packing
        notation
      }
    }
  }
`;

export { HoneyHero, honeyHeroQuery };
