import React from "react";
import Helmet from "react-helmet";
import { HoneyAdvantages } from "../components/HoneyAdvantages";
import { HoneyHero } from "../components/HoneyHero";
import { HoneyOtherProducts } from "../components/HoneyOtherProducts";
import { HoneySugar } from "../components/HoneySugar";
import { Layout } from "../components/Layout";

const HoneyPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <HoneyHero {...props.honeyHero} />
      <HoneyAdvantages formBody={layout.formBody} {...props.honeyAdvantages} />
      <HoneySugar {...props.honeySugar} />
      <HoneyOtherProducts {...props.honeyOtherProducts} />
    </>
  );
};

HoneyPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...HoneyHero.getLivePreviewData(props),
  ...HoneyAdvantages.getLivePreviewData(props),
  ...HoneySugar.getLivePreviewData(props),
  ...HoneyOtherProducts.getLivePreviewData(props),
});

const HoneyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout {...data.layout.frontmatter}>
      <HoneyPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query HoneyPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "honey" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }
        ...HoneyHeroFragment
        ...HoneyAdvantagesFragment
        ...HoneySugarFragment
        ...HoneyOtherProductsFragment
      }
    }
  }
`;

export default HoneyPage;

export { pageQuery, HoneyPageTemplate };
