import React from "react";
import { Image } from "../../Image";

import "./styles.css";

const HoneyHeroProduct = ({ photo, packing, notation, alt }) => {
  return (
    <div className="HoneyHeroProduct">
      <div className="HoneyHeroProduct__image">
        <Image image={photo} alt={alt} />
      </div>
      <div className="HoneyHeroProduct__content">
        <div className="Font_headerMobile">{packing}</div>
        <div className="Font_mediumText Color_lightGrey">{notation}</div>
      </div>
    </div>
  );
};

export { HoneyHeroProduct };
