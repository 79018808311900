import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import { Hexagon } from "./icon";
import "./styles.css";

const HoneySugar = ({ subtitle, title, text, background, alt }) => {
  return (
    <section className="HoneySugar">
      <div className=" [ HoneySugar__container ] [ Content ]">
        <div className="HoneySugar__description">
          <div className=" [ HoneySugar__subtitle ] [ Font_smallHeaderCaps ]">{subtitle}</div>
          <div className=" [ HoneySugar__title ] [ Font_bigHeaderSerif ] ">{title}</div>
          <div className="HoneySugar__descriptionContainer">
            <Hexagon className="HoneySugar__hexagon" />
            <div className=" [ HoneySugar__text ] [ Font_regularText ]"> {text}</div>
          </div>
        </div>
        <div className="HoneySugar__image">
          <div className="HoneySugar__background">
            <Image image={background} alt={alt} />
          </div>
        </div>
      </div>
    </section>
  );
};

HoneySugar.getLivePreviewData = ({ data, getAsset }) => ({
  honeySugar: {
    ...data.honeySugar,
    backgound: getAsset(data.honeySygar.backgound).url,
  },
});

const honeySugarQuery = graphql`
  fragment HoneySugarFragment on MarkdownRemarkFrontmatter {
    honeySugar {
      subtitle
      title
      text
      background {
        childImageSharp {
          fluid(maxWidth: 1728) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
    }
  }
`;

export { HoneySugar, honeySugarQuery };
