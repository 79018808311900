import React from "react";
import { HexagonYellow } from "../../ContactUs/ContactUsContainer/icons/HexagonYellow";
import "./styles.css";

const HoneyHeroContent = ({ subtitle, title, description }) => {
  return (
    <div className="HoneyHeroContent">
      <div className="Font_smallHeaderCaps sm:Font_mediumTextCaps Color_lightGrey">{subtitle}</div>
      <div className="[ HoneyHeroContent__title ] [ Font_bigHeaderSerif Color_black ]">{title}</div>
      <div className="HoneyHeroContent__descriptionContainer">
        <HexagonYellow className="HoneyHeroContent__hexagon" />
        <div className="Font_regularText Color_darkGrey">{description}</div>
      </div>
    </div>
  );
};

export { HoneyHeroContent };
